<template>
	<div class="col-auto px-0">
		<p class="text-general f-15 mb-3">{{ texto }}</p>
		<div v-if="tieneData">
			<div class="space-image">
				<div class="circled-cancel" @click="confirmarEliminar">
					<el-tooltip content="Eliminar" placement="bottom" effect="light">
						<i class="icon-cancel-circled f-20" />
					</el-tooltip>
				</div>
				<img :src="data.imagen" class="obj-cover border br-4" width="100%" height="100%" />
			</div>
		</div>
		<div v-else>
			<el-upload ref="upload" action="#" accept="image/svg+xml" :on-change="changeFile" :on-remove="removeFile"
				:auto-upload="false" :multiple="false" :limit="1" :show-file-list="false">
				<div class="space-image">
					<img src="/img/no-imagen/añadir_imagen.svg" class="obj-cover border br-4" width="100%" height="100%" />
					<p class="f-13 text-general2 mb-5 text-left">
						Formato: SVG<span style="float:right">1:1</span>
					</p>
				</div>
			</el-upload>
		</div>

		<!--
        <div class="space-image" @click="subirImagen">
            <div v-if="tieneData" class="circled-cancel" @click="confirmarEliminar">
                <el-tooltip content="Eliminar" placement="bottom" effect="light">
                    <i class="icon-cancel-circled f-20" />
                </el-tooltip>
            </div>

            <img :src="tieneData ? data.imagen: '/img/no-imagen/añadir_imagen.svg'" class="obj-cover border br-4" width="100%" height="100%" />
            <p class="f-13 text-general2 mb-5">
                Formato: SVG<span style="float:right">1:1</span>
            </p>
        </div>
        -->
		<!-- Partials -->
		<!--
        <modal-subir-imagen-estados ref="modalSubirImagenEstados" @actualizar="actualizar" />
        -->
		<modal ref="confirmarEliminar" titulo="Eliminar imagen" no-aceptar adicional="Eliminar" @adicional="eliminarImagen">
			<div class="row mx-0 justify-center">
				<div class="col text-center">
					<p>¿Está seguro de eliminar esta imagen?</p>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
	components: {
		//modalSubirImagenEstados: () => import('../partials/modalSubirImagenEstados')
	},
	props: {
		texto: {
			type: String,
			default: ''
		},
		tipo: {
			type: Number,
			default: 0
		},
		data: {
			type: Object,
			default: () => { }
		},
	},
	data() {
		return {
			tieneData: false,
		}
	},
	mounted() {
		this.controlData();
	},
	methods: {
		controlData() {
			if (!_.isEmpty(this.data)) {
				this.tieneData = true;
			}
		},
		async changeFile(file) {
			this.file = file.raw;
			let extension = file.raw.type;
			if (this.file != null) {
				if (extension != 'image/svg+xml') {
					this.notificacion('Alerta', 'El formato de la imagen seleccionada no corresponde con el permitido (SVG)', 'warning');
					return this.removeFile();
				}
				try {
					let model = {
						tipo: this.tipo,
						file: this.file,
						dimension: 2000,
					};
					model = this.crear_formData(model)
					const {data} = await Service.postMovil(model)
					this.notificacion('Mensaje', "Imagen actualizada correctamente", data.tipo);
					this.removeFile();
					this.actualizar(data.data);
				} catch (error) {
					this.error_catch(error);
				}
			}
		},
		removeFile() {
			this.file = null;
		},
		subirImagen() {
			if (!this.tieneData) {
				this.$refs.modalSubirImagenEstados.toggle(this.tipo);
			}
		},
		confirmarEliminar() {
			this.$refs.confirmarEliminar.toggle();
		},
		async eliminarImagen() {
			try {
				this.$refs.confirmarEliminar.toggle();
				const { data } = await Service.deleteImagenWebMovil(this.data.id)
				this.notificacion('Mensaje', data.mensaje, data.tipo);
				this.limpiar();
			} catch (error) {
				this.error_catch(error)
			}
		},
		async limpiar() {
			this.data = {};
			this.tieneData = false;
		},
		async actualizar(data) {
			console.log(data);
			this.data = data;
			this.tieneData = true;
		},
	}
}
</script>

<style lang="scss" scoped>
.space-image {
	width: 240px;
	height: 240px;
	position: relative;
	border-radius: 4px;
	cursor: pointer;

	.circled-cancel {
		width: 28px;
		height: 28px;
		right: 11px;
		top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		position: absolute;
		cursor: pointer;
		z-index: 5;
		color: #FFFFFF;
		background-color: #7F7F7F;
	}

	img {
		border-radius: 4px;
		object-fit: cover;
	}
}
</style>
